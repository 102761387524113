import {Action, Selector, State, StateContext} from '@ngxs/store';
import {Injectable} from '@angular/core';
import {WorkupEnhancedControllerService} from '@matchsource/api-generated/subject';
import {GetPatientIdenitytyVerified} from './identity-verification.actions';
import {tap} from 'rxjs/operators';
import {EntityStateModel} from '@matchsource/store/core';

export type IdentityVerificationStateModel = EntityStateModel<boolean>;

@State<IdentityVerificationStateModel>({
  name: 'identityVerified',
  defaults: {
    loading: false,
    loaded: false,
    error: false,
    entity: false,
  },
})
@Injectable()
export class IdentityVerificationState {
  constructor(private readonly workupEnhancedService: WorkupEnhancedControllerService) {}

  @Selector([IdentityVerificationState])
  static loading(state: IdentityVerificationStateModel) {
    return state.loading;
  }

  @Selector([IdentityVerificationState])
  static loaded(state: IdentityVerificationStateModel) {
    return state.loaded;
  }

  @Selector([IdentityVerificationState])
  static identityVerified(state: IdentityVerificationStateModel) {
    return state.entity;
  }

  @Selector([IdentityVerificationState])
  static inProgressOrLoaded(state: IdentityVerificationStateModel) {
    return state.loaded || state.loading;
  }

  @Action(GetPatientIdenitytyVerified)
  get(ctx: StateContext<IdentityVerificationStateModel>, {patientId}: GetPatientIdenitytyVerified) {
    ctx.patchState({
      loading: true,
    });

    return this.workupEnhancedService.getLatestInProgressVerificationByGuid({guid: patientId}).pipe(
      tap(identity => {
        ctx.patchState({
          entity: identity,
          loaded: true,
          loading: false,
        });
      })
    );
  }
}
